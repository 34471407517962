import Header from '../Components/Header'
import Footer from '../Components//Footer'
import { Link,useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet';

function Terms() {
  const location = useLocation();
  return (
    <>
    <Helmet>
      <title>Terms of Service</title>
      <meta name="description" content="These Terms of Service govern your use of our website and services. By accessing or using our platform, you agree to comply with these terms. Please read them carefully to understand your rights and obligations. For any questions, feel free to contact us." />
    </Helmet>

    <span className="d-none">{location.pathname}</span>
    <Header/>

    <section className="termsPage">
      <div className="container">
        <div className="row pt-5">
          <div className="col-lg-12">
            <h1 className="display-1 fw-bold mb-3 lh-1 titillium-web-bold"><span className="d-block fs-5 text-uppercase text-logo">Legal</span>Terms of Service</h1>
          </div>
          <div className='col-lg-12'>
              <div className='support-body-inner text-slate-800'>
                  <p className="fs-5 mb-5">The following terms and conditions outline the rules and regulations for the use of website and the services I provide. By accessing this website and using our services, you agree to accept and comply with these terms. Please read them carefully.</p>

                  <p className="fs-5 mb-4"><strong className="d-block fs-5 fw-bold titillium-web-semibold">Status: </strong>Harshal Thorat operates as an independent freelancer. Any reference to "we," "us," or "our" pertains to www.digitalharshal.com</p>

                  <p className="fs-5 mb-4"><strong className="d-block fs-5 fw-bold titillium-web-semibold">White Labeling Services:</strong> In addition to our regular services, Harshal Thorat offers white labeling services. Please contact me directly to discuss the specifics of white labeling for your project.
                  <strong> Email: </strong><span className=''><Link to='mailto:contact@digitalharshal.com'>contact@digitalharshal.com</Link></span> | <strong> Mobile Number: </strong><span className=''><Link to='tel:7040617823'>+91-7040617823</Link></span></p>

                  <p className="fs-5 mb-4"><strong className="d-block fs-5 fw-bold titillium-web-semibold">Non-Refundable Services: </strong>All services provided by www.digitalharshal.com are non-refundable. Once a project has been initiated and work has commenced, no refunds will be issued.</p>

                  <p className="fs-5 mb-4"><strong className="d-block fs-5 fw-bold titillium-web-semibold">Payment Terms: </strong>Clients are required to make a 50% payment after confirming their email and agreeing to the terms of services provided by www.digitalharshal.com. The remaining 50% is due prior to the project being uploaded to the client's server or domain.</p>

                  <p className="fs-5 mb-4"><strong className="d-block fs-5 fw-bold titillium-web-semibold">Maintenance Fee: </strong>Upon completion of the project, clients will be subject to a maintenance fee of $50 per month for a minimum period of 6 months or 1 year, as agreed upon during the project initiation. This fee is designed to cover ongoing support, updates, and maintenance services.</p>

                  <p className="fs-5 mb-4"><strong className="d-block fs-5 fw-bold titillium-web-semibold">Applicability: </strong>These terms and conditions apply to all services and products offered by www.digitalharshal.com. By engaging with us, you acknowledge and accept these terms.</p>

                  <p className="fs-5 mb-4">By using our website and services, you agree to adhere to these terms and conditions. www.digitalharshal.com reserves the right to update or modify these terms at any time without prior notice. It is the responsibility of the client to regularly check for changes. If you have any questions or concerns regarding these terms, please contact me before proceeding with our services.</p>

                  <p className="fs-5 mb-5 font-bold">Thank you for choosing <span className='text-orange-500'>www.digitalharshal.com!</span></p>
              </div>
          </div>
        </div>
      </div>
    </section>

    <Footer/>
    </>
  )
}

export default Terms