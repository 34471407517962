import PagesRoutes from "./PagesRoutes";

function App() {
  return (
    <>
      <PagesRoutes/>
    </>
  );
}

export default App;
