import { Link } from "react-router-dom";
import Footer from "../Components/Footer"
import '../assets/styles/consulting.css';
import Logo from '../assets/media/digitalharshal-logo.svg'
import artcad from '../assets/media/artcad.png'
import ebrandz from '../assets/media/ebrandz-logo.png'
import pashumitra from '../assets/media/pashumitra-logo.svg'
import brandz from '../assets/media/brandz-Logo.webp'
import datagrid from '../assets/media/datagrid-logo.png'

import HarshalPhoto from "../assets/media/profile-photo.png"

import { useLocation } from 'react-router-dom';
import { Helmet } from "react-helmet";
import ConsultingForm from "../Components/ConsultingForm";

function Consulting() {
  const location = useLocation();

  return (
    <>
    <span className="d-none">{location.pathname}</span>
    <Helmet>
        <title>Full-Service Digital Solutions for Your Business</title>
        <meta name="description" content="I specialize in delivering high-quality, efficient digital solutions. My services include expert website design and development, app creation, and comprehensive digital marketing strategies. With round-the-clock support, I ensure your online presence remains strong and effective." />
        <link rel="canonical" href="https://digitalharshal.com/consulting" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:locale:alternate" content="es_ES" />
        <meta property="og:locale:alternate" content="it_IT" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Full-Service Digital Solutions for Your Business" />
        <meta property="og:description" content="I specialize in delivering high-quality, efficient digital solutions. My services include expert website design and development, app creation, and comprehensive digital marketing strategies. With round-the-clock support, I ensure your online presence remains strong and effective." />
        <meta property="og:url" content="https://digitalharshal.com/consulting" />
        <meta property="og:site_name" content="Harshal Thorat" />
        <meta property="article:modified_time" content="2024-01-12T10:00:00+00:00" />
        <meta property="og:image" content="https://digitalharshal.com/schema/consulting.jpg" />
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "Person",
            "name": "Harshal Thorat",
            "url": "https://digitalharshal.com/consulting",
            "image": "https://digitalharshal.com/schema/consulting.png",
            "jobTitle": "Freelancer",
            "description": "I specialize in delivering high-quality, efficient digital solutions. My services include expert website design and development, app creation, and comprehensive digital marketing strategies. With round-the-clock support, I ensure your online presence remains strong and effective.",
            "sameAs": [
                "https://www.instagram.com/iharshalthorat/",
                "https://www.linkedin.com/in/iharshalthorat/",
                "https://www.threads.net/@iharshalthorat",
            ]
          }
        `}</script>
    </Helmet>


    <div className='consulting-header'>
      <div className='container'>
        <div className='row-consulting-header'>
          <Link to="/" className='d-flex consulting-logo'><img src={Logo} alt='Digital Harshal'/></Link>
          <Link to="/" className='fs-6 backhome fw-semibold'> Back to Home Page</Link>
        </div>
      </div>
    </div>

    <section className="consultingHero">
      <div className="container">
        <div className="row">
          <div className='consulting col-lg-5'>
            <div className='consulting-form-wrap'>
              <h2 className="fs-3 fw-light text-slate-800 mb-4 text-center">Let's Discuss Your Needs</h2>
              <ConsultingForm/>
            </div>
          </div>
          <div className='col-lg-7'>
            <div className='consulting-frm-inner'>
              <h2 className='fs-4'>Hey, I'm Harshal</h2>
              <h1 className='display-4 fw-bold lh-1 titillium-web-bold'>Discover How I Can Support <span className="green-text">Your Success!</span></h1>
              <ul className="consulting-list1">
                <li>UI/UX Design</li>
                <li>Website Design and Development</li>
                <li>Mobile App Development</li>
                <li>Digital Marketing</li>
                <li>Tech Consulting</li>
              </ul>
              <ul className="fs-6 consulting-list2">
                <li><strong>UI/UX Consulting</strong> - <span>Craft user-centric designs that enhance usability and delight your audience.</span></li>
                <li><strong>Digital Strategy</strong> - <span>Develop a customized plan aligning digital efforts with your business objectives.</span></li>
                <li><strong>Brand Identity Consulting</strong> - <span>Create a cohesive brand identity that resonates with your target audience.</span></li>
                <li><strong>Marketing Insights</strong> - <span>Utilize data-driven insights to optimize marketing strategies and maximize ROI.</span></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="workwith">
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <h2 className="fs-3 titillium-web-bold lh-1">Work with Leading Projects and Companies</h2>
          </div>
          <div className="col-lg-9">
            <div className="workwith-logos">
              <img src={artcad} alt='Artcadindia - leading software firms in designing attractive and professional websites and web applications in Nashik, Maharashtra' className="img-fluid"/>
              <img src={ebrandz} alt='ebrandz - Marketing agency in Mumbai, Maharashtra' className="img-fluid"/>
              <img src={pashumitra} alt='Pashumitra app developed for Farmers, Livestock Owner, Pet Owner, Dairy Farms and Veterinary and Farm Consultant' className="img-fluid"/>
              <img src={brandz} alt='brandz on web - Software company in Nashik, Maharashtra' className="img-fluid"/>
              <img src={datagrid} alt='datagrid solutions - Software company in Nashik, Maharashtra' className="img-fluid"/>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className='about-me-consulting'>
      <div className="container">
        <div className="row">
          <div className='col-lg-4'>
            <div className='about-photo m-auto'>
              <img src={HarshalPhoto} className='img-fluid'/>
            </div>
          </div>
          <div className='col-lg-8'>
            <div className='test-wrap'>
              <h2 className='display-4 fw-bold lh-1 titillium-web-bold yellow-text'>Don't Hire agency, Hire Freelancer who work and available all the time 24/7</h2>
              <p className='fs-4 text-white'>Don't waste your extra time and extra money on tech agency. Connect with me and get advice as a technical consultant to build your digital presence like website and branding.</p>
            </div>
            {/* <Link to="tel:+91-7040617823" className='call-now-about-consulting'><img src={Whatsapp} alt='Whatsapp'/>91-7040617823</Link> */}
            {/* <p className='f-body-0 d-block mt-3 text-white'>
               <Link to="mailto:contact@digitalharshal.com" className='call-now-about-consulting'><img src={businessemail} alt='Whatsapp'/>contact@digitalharshal.com</Link>
            </p> */}
          </div>
        </div>
      </div>
    </section>

    <section className='book-call'>
      <div className='container'>
        <div className='row'>
          <h2 className='display-5 fw-bold lh-1 titillium-web-bold'>Strategic Consultation to Uncover Your <span className='fw-bold yellow-text d-block'>Business, Goals, and Challenges</span></h2>
          <div className='col-12 col-md-6 col-sm-6 col-lg-3'>
            <div className='card step-card border-0'>
              <span className='stepnum fs-3 yellow-bg titillium-web-bold'>1</span>
              <h3 className='fs-5 fw-semibold lh-1 mt-2'>Needs Assessment</h3>
              <p className='fs-6 text-secondary'>Conduct a thorough analysis to identify specific areas of improvement.</p>
            </div>
          </div>
          <div className='col-12 col-md-6 col-sm-6 col-lg-3'>
            <div className='card step-card border-0'>
              <span className='stepnum fs-3 yellow-bg titillium-web-bold'>2</span>
              <h3 className='fs-5 fw-semibold lh-1 mt-2'>Strategy Development</h3>
              <p className='fs-6 text-secondary'>Formulate a tailored strategy to address identified needs.</p>
            </div>
          </div>
          <div className='col-12 col-md-6 col-sm-6 col-lg-3'>
            <div className='card step-card border-0'>
              <span className='stepnum fs-3 yellow-bg titillium-web-bold'>3</span>
              <h3 className='fs-5 fw-semibold lh-1 mt-2'>Implementation Guidance</h3>
              <p className='fs-6 text-secondary'>Provide detailed guidance on implementing recommended strategies.</p>
            </div>
          </div>
          <div className='col-12 col-md-6 col-sm-6 col-lg-3'>
            <div className='card step-card border-0'>
              <span className='stepnum fs-3 yellow-bg titillium-web-bold'>4</span>
              <h3 className='fs-5 fw-semibold lh-1 mt-2'>Monitoring and Adjustments</h3>
              <p className='fs-6 text-secondary'>Continuously monitor results and make adjustments for ongoing success.</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Footer/>
    </>
  )
}

export default Consulting