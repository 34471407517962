import { Link } from 'react-router-dom';

import WebsiteDevelopement from '../assets/media/website-development.png';
import DigitalMarketing from '../assets/media/digital-marketing.png';
import AppDevelopment from '../assets/media/app-development.png';

import { useEffect } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const StickySection = () => {
  useEffect(() => {
		let ctx = gsap.context(() => {
			gsap.set(".photo:not(:first-child)", { opacity: 0 , scale: 0 })
			const animation = gsap.to(".photo:not(:first-child)", {
				opacity: 1, scale: 1, duration: 1, stagger: 1, ease: "power2.out",
			})

			ScrollTrigger.create({
				trigger: ".appdetails",
				start: "top top",
				end: "bottom bottom",
				pin: ".left-column-inner",
				animation: animation,
				scrub: true,
				// markers: true,
			})
		})
		return () => ctx.revert();
	}, [])
  return (
    <div className='appdetails'>
      <div className="container">
        <div className="row">
          <div className="left-column">
            <div className="left-column-inner">
              <div className='app-scroll'>
                <div className='mobile-screens photo'>
                  <img src={WebsiteDevelopement} alt="Website Development"/>
                </div>
                <div className='mobile-screens photo'>
                  <img src={DigitalMarketing} alt="Digital Marketing"/>
                </div>
                <div className='mobile-screens photo'>
                  <img src={AppDevelopment} alt="Mobile App Development"/>
                </div>
              </div>
            </div>
          </div>
          <div className="right-column">
            <div className='vh-100-max'>
              <div className='d-flex flex-column align-items-start justify-content-center h-100'>
                <div className='mobile-view-stick'>
                  <img src={WebsiteDevelopement} alt="Website Developement" className='img-fluid'/>
                </div>
                <h2 className="display-2 titillium-web-bold lh-1">Website Development</h2>
                <div className='line'></div>
                <p className='fs-5 lh-2'>Crafting visually stunning and highly functional websites is my specialty. Whether you need a simple landing page or a complex e-commerce platform, I ensure your site is responsive, user-friendly, and optimized for search engines.</p>
                <Link to="/contact" className="btn yellow-bg yellow-btn rounded-pill d-inline-block px-5 py-3 mt-2 fs-6 fw-semibold">Contact Now</Link>
              </div>
            </div>
            <div className='vh-100-max'>
              <div className='d-flex flex-column align-items-start justify-content-center h-100'>
                <div className='mobile-view-stick'>
                  <img src={DigitalMarketing} alt="Digital Marketing" className='img-fluid'/>
                </div>
                <h2 className="display-2 titillium-web-bold lh-1">Digital Marketing</h2>
                <div className='line'></div>
                <p className='fs-5 lh-2'>Boost your online presence and drive traffic to your website with my comprehensive digital marketing services. From SEO and content marketing to social media management and PPC campaigns, I tailor strategies that align with your business goals.</p>
                <Link to="/contact" className="btn yellow-bg yellow-btn rounded-pill d-inline-block px-5 py-3 mt-2 fs-6 fw-semibold">Contact Now</Link>
              </div>
            </div>
            <div className='vh-100-max'>
              <div className='d-flex flex-column align-items-start justify-content-center h-100'>
                <div className='mobile-view-stick'>
                  <img src={AppDevelopment} alt="Mobile App Development" className='img-fluid'/>
                </div>
                <h2 className="display-2 titillium-web-bold lh-1">Mobile App Development</h2>
                <div className='line'></div>
                <p className='fs-5 lh-2'>Take your business mobile with a custom-designed app. I create seamless, high-performance applications for both Android and iOS platforms, ensuring they are intuitive and engaging for users.</p>
                <Link to="/contact" className="btn yellow-bg yellow-btn rounded-pill d-inline-block px-5 py-3 mt-2 fs-6 fw-semibold">Contact Now</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StickySection;
