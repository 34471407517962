import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import StickySection from '../Components/StickySection'
import Profile from '../assets/media/profile-photo.png'
import artcad from '../assets/media/artcad.png'
import ebrandz from '../assets/media/ebrandz-logo.png'
import pashumitra from '../assets/media/pashumitra-logo.svg'
import brandz from '../assets/media/brandz-Logo.webp'
import datagrid from '../assets/media/datagrid-logo.png'

import { useLocation } from 'react-router-dom';
function Home() {
  const location = useLocation();
  return (
    <>
    <Helmet>
      <title>Freelancer | Website Design, Digital Marketing, App Development & Consulting | Digital Harshal</title>
      <meta name="description" content="Discover top-notch freelance services including website design & development, digital marketing, app development, and consulting. Partner with me at Digital Harshal to transform your digital presence and achieve your business goals. Contact now for tailored solutions!" />
    </Helmet>
    <Header/>

    <span className="d-none">{location.pathname}</span>

    <section className="hero">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-12">
            <h1 className='display-1 lh-1 titillium-web-regular'>If you're searching for a <span className="display-1 lh-1 titillium-web-bold yellow-text d-block">Website designer &amp; Developer</span> to transform your ideas into reality</h1>
            <Link to="/consulting" className="btn yellow-bg yellow-btn rounded-pill d-inline-block px-5 py-3 mt-2 fs-6 fw-semibold">Let's Work Together!</Link>
          </div>
        </div>
      </div>
    </section>

    <StickySection/>
    
    <section className="collaborations">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h2 className="display-5 fw-semibold text-center titillium-web-regular lh-1">Work with <span className="yellow-text d-block titillium-web-bold">Leading Projects and Companies</span></h2>
          </div>
          <div className="colab-logos">
            <img src={artcad} alt='Artcadindia - leading software firms in designing attractive and professional websites and web applications in Nashik, Maharashtra' className="img-fluid"/>
            <img src={ebrandz} alt='ebrandz - Marketing agency in Mumbai, Maharashtra' className="img-fluid"/>
            <img src={pashumitra} alt='Pashumitra app developed for Farmers, Livestock Owner, Pet Owner, Dairy Farms and Veterinary and Farm Consultant' className="img-fluid"/>
            <img src={brandz} alt='brandz on web - Software company in Nashik, Maharashtra' className="img-fluid"/>
            <img src={datagrid} alt='datagrid solutions - Software company in Nashik, Maharashtra' className="img-fluid"/>
          </div>
        </div>
      </div>
    </section>
    
    <section className="quick">
      <div className="container">
        <h2 className="display-5 titillium-web-semibold lh-1 mb-3">Want to build a credible and relatable Website <br></br>for your target audience?</h2>
        <Link to="/consulting" className="btn yellow-white rounded-pill d-inline-block px-5 py-3 mt-2 fs-6 fw-bold">Let's Work Together!</Link>
      </div>
    </section>

    <Footer/>
    </>
  )
}

export default Home