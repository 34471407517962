import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from './pages/Home'
import About from './pages/About'
import Contact from './pages/Contact'
import Terms from './support/Terms'
import NotFound from './pages/NotFound'
import Services from './pages/Services'
import Consulting from './pages/Consulting'

function PagesRoutes() {
  return (
    <Routes>
      <Route exact path='/' element={<Home/>}/>
      <Route path='/about' element={<About/>}></Route>
      <Route path='/contact' element={<Contact/>}></Route>
      <Route path='/consulting' element={<Consulting />}></Route>
      
      {/* Services */}
      <Route path='/services' element={<Services />}></Route>
      
      <Route path='/terms-of-use' element={<Terms/>}></Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default PagesRoutes