import { Link } from "react-router-dom"
import Footer from "../Components/Footer"
import Header from "../Components/Header"

function NotFound() {
  return (
    <>
    <Header/>
    <div className="notfound">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <h1 className="fs-1 m-0 titillium-web-bold">404 - Not Found</h1>
            <p className="fs-4">The page you are looking for does not exist.</p>
            <Link to="/contact" className="btn yellow-bg yellow-btn rounded-pill d-inline-block px-5 py-3 mt-2 fs-6 fw-semibold me-2">Contact Me</Link> 
            <Link to="/contact" className="btn white-yellow rounded-pill d-inline-block px-5 py-3 mt-2 fs-6 fw-semibold">Back to Home Page!</Link>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
    </>
  )
}

export default NotFound