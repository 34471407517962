import { Link } from "react-router-dom"
import Footer from "../Components/Footer"
import Header from "../Components/Header"
import Profile from '../assets/media/profile-photo.png'

import { useLocation } from 'react-router-dom';
import { Helmet } from "react-helmet";
function About() {
  const location = useLocation();
  return (
    <>
    <Helmet>
      <title>Digital Harshal | Freelance Website Design, Digital Marketing & App Development</title>
      <meta name="description" content="Discover Digital Harshal, your go-to freelancer for website design, digital marketing, app development, and consulting services. Elevate your brand and achieve your business goals with tailored solutions. Contact us today!" />
    </Helmet>
    <span className="d-none">{location.pathname}</span>
    <Header/>

    <section className="hero">
      <div className="container-lg">
        <div className="row align-items-center">
          <div className="col-lg-12">
            <h1 className='display-1 lh-1 titillium-web-regular'>I’ll open the floodgates to consistently <span className="display-1 titillium-web-bold yellow-text">profitable for your business</span></h1>
            <p className="fs-4">I’ve worked with clients of all size in pretty much every industry, getting results in some of the most competitive online niches like car rentals, ecommerce, Wellness and many more.</p>
          </div>
        </div>
      </div>
    </section>

    <section className="dh-message">
      <div class="container">
        <div class="row">
        {/* <div class="col-lg-1"></div> */}
          <div class="col-lg-4">
            <div className="about-profile">
              <img src={Profile} alt='Digital Harshal' className="img-fluid"/>
            </div>
          </div>
          <div class="col-lg-8">
            <p class="fs-2 lh-1 titillium-web-semibold mt-2"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="mb-1 me-2" viewBox="0 0 16 16"><path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6m0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8M8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0m0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13m8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5M3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8m10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0m-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0m9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707M4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708"/></svg>Essential Steps to Reach Your Revenue Targets</p>
            <p class="fs-4 titillium-web-regular">My main goal is to enhance your revenue. I will evaluate your business to pinpoint opportunities for improvement and create a tailored strategy to boost your income. Before we start working together, I’ll present calculated estimates of how much you could earn under conservative, moderate, and aggressive projections.</p>
            <p class="fs-2 lh-sm titillium-web-bold sm:text-3xl">"Harshal Thorat"</p>
          </div>
        </div>
      </div>
    </section>

    
    <section className="quick">
      <div className="container">
        <h2 className="display-5 titillium-web-semibold lh-1 mb-3">Want to build a credible and relatable Website <br></br>for your target audience?</h2>
        <Link to="/contact" className="btn yellow-white rounded-pill d-inline-block px-5 py-3 mt-2 fs-6 fw-bold">Let's Work Together!</Link>
      </div>
    </section>
    <Footer/>
    </>
  )
}

export default About