import Footer from "../Components/Footer"
import Header from "../Components/Header"
import { Link, useLocation } from 'react-router-dom';
function Services() {
  const location = useLocation();

  return (
    <>
    <span className="d-none">{location.pathname}</span>
    <Header/>

    <h1>Digital Harshal offers:</h1>
    
    
    <section className="quick">
      <div className="container">
        <h2 className="display-5 titillium-web-semibold lh-1 mb-3">Want to build a credible and relatable Website <br></br>for your target audience?</h2>
        <Link to="/contact" className="btn yellow-white rounded-pill d-inline-block px-5 py-3 mt-2 fs-6 fw-bold">Let's Work Together!</Link>
      </div>
    </section>
    <Footer/>
    </>
  )
}

export default Services