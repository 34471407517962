import React, { useState } from 'react';
import axios from 'axios';

const ConsultingForm = () => {
    const [fullname, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [service, setService] = useState('');
    const [message, setMessage] = useState('');
    const [responseMessage, setResponseMessage] = useState('');

    const [isSuccess, setIsSuccess] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (!fullname || !email || !phone || !service || !message) {
            setIsSuccess(false);
            setResponseMessage('Please fill in all fields before submitting.');
            return;
        }
        
        try {
            const response = await axios.post('https://digitalharshal.com/api/consulting.php', {
                fullname,
                email,
                phone,
                service,
                message,
            });
            // console.log("API Response:", response.data); // Log the API response

            setIsSuccess(true); // Set success state
            setResponseMessage('Success! Your consultation request has been submitted!');

            // if (response.data.status && response.data.status.toLowerCase() === 'success') {
            //     setResponseMessage(response.data.message || 'Success! Your consultation request has been submitted!');
            // } 
            // else {
            //     setResponseMessage(response.data.message);
            //     setIsSuccess(false); // Set failure state
            // }

            // Clear all fields after processing the response
            setName('');
            setEmail('');
            setPhone('');
            setService('');
            setMessage('');
        } catch (error) {
            console.error('Error:', error);
            setIsSuccess(false); // Set failure state
            setResponseMessage('An error occurred while submitting the form.');

            // Clear fields on error as well
            setName('');
            setEmail('');
            setPhone('');
            setService('');
            setMessage('');
        }
    };

    return (
        <form onSubmit={handleSubmit} className='row g-2'>
        <div className='col-lg-12'>
            <input type="text" placeholder="Name" value={fullname} onChange={(e) => setName(e.target.value)}  className='w-full form-control'/>
        </div>
        <div className='col-lg-6'>
            <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)}  className='w-full form-control'/>
        </div>
        <div className='col-lg-6'>
            <input type="number" placeholder="Phone" value={phone} onChange={(e) => setPhone(e.target.value)}  className='w-full form-control'/>
        </div>
        <div className='col-lg-12'>
            <select value={service} onChange={(e) => setService(e.target.value)} className="form-select" >
                <option value="" className='text-slate-500'>Choose a Service</option>
                <option value="Web design and development">Web design and development</option>
                <option value="Digital Marketing">Digital marketing</option>
                <option value="Mobile App Development">Mobile app development</option>
                <option value="Other">Other</option>
            </select>
        </div>
        <div className='col-lg-12'>
            <textarea placeholder="Message" value={message} onChange={(e) => setMessage(e.target.value)}   className='w-full form-control'/>
        </div>
        <div className='col-lg-12 mt-3'>
            
            {responseMessage && (
                <div className={`alert text-center rounded-0 ${isSuccess ? 'alert-success' : 'alert-danger'}`}>
                {responseMessage}
            </div>
            )}
            <button type="submit" className='btn yellow-bg yellow-btn rounded-pill d-inline-block px-5 py-3 fs-6 fw-semibold'>Send</button>
        </div>
        </form>
    );
};

export default ConsultingForm;
