import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'
import Logo from '../assets/media/digitalharshal-logo.svg'

function Header() {
  const location = useLocation(); // Get current location
  const [menuOpen, setMenuOpen] = useState(false);
  const [scrolling, setScrolling] = useState(false);

  const toggleMobileMenu = () => { setMenuOpen(!menuOpen) };
  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      if (isScrolled !== scrolling) {
        setScrolling(isScrolled);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrolling]);
  const headerClass = scrolling ? 'scrolled' : '';

  return (
    <header id="mastheadHome" className={headerClass}>
      <div className='container'>
        <div className="header-row h-16">
          <div className="logo-nav">
            <Link to="/" className="text-black fw-bold logo">
              <img src={Logo} alt='Digital Harshal'/>
            </Link>
            {/* <span className="alert alert-success rounded-pill px-3 py-2 m-0 ms-3"> Available for Work</span> */}
            <ul className={`topnav fw-semibold ${menuOpen ? 'mobile-hidden' : ''}`}>
                <li className={location.pathname === '/' ? 'active' : ''}><Link to="/" className="position-relative">Home</Link></li>
                <li className={location.pathname === '/about' ? 'active' : ''}><Link to="/about" className="position-relative">About</Link></li>
                {/* <li className={location.pathname === '/services' ? 'active' : ''}><Link to="/services" className="position-relative ">Services</Link></li> */}
                <li className={location.pathname === '/contact' ? 'active' : ''}><Link to="/contact" className="position-relative">Contact</Link></li>
                
                <li className={location.pathname === '/consulting' ? 'login active' : 'login desk-none'}><Link to="/consulting" className="position-relative consulting-lia text-uppercase fw-bold">Consulting</Link></li>

                {/* <li className={location.pathname === '/contact' ? 'active' : ''}><Link to="/contact" className="position-relative desk-none">Contact</Link></li> */}
                {/* <li><Link to="/contact" className="consulting-lia desk-none fw-semibold position-relative ">Contact</Link></li> */}
                
            </ul>
            <div className={`burger-menu ${menuOpen ? 'active' : ''}`} onClick={toggleMobileMenu}>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
            </div>
          </div>
          <ul className="useoptions mobile-hide">
            <li className={location.pathname === '/consulting' ? 'login active' : 'login'}><Link to="/consulting" className="consulting-lia text-uppercase fw-bold">Consulting</Link></li>
          </ul>
        </div>
      </div>
    </header>
  )
}

export default Header